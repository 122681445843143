import React from 'react'
import { Container, Divider, Grow, Grid } from '@mui/material'
import PersonCard from '../components/PersonCard'
import {Helmet} from "react-helmet";

function createData(name, photo, position, link, title='') {
    return { name, photo, position, link, title };
}

const titleName = "BrainNN22 - Organization"

const rows = [
    createData('Carl Yang', '/people/CarlYang.jpg', 'Emory University',
        'https://www.cs.emory.edu/~jyang71/', 'General Chair'),
    createData('Xiaoxiao Li', '/people/XiaoxiaoLi.jpg', 'University of British Columbia',
        'https://xxlya.github.io/xiaoxiao/', 'Program Co-Chair'),
    createData('Qingyu Zhao', '/people/QingyuZhao.png', 'Stanford University',
        'https://stanford.edu/~qingyuz/', 'Program Co-Chair'),
]

const studentRows = [
    createData('Hejie Cui', '/people/HejieCui.jpeg', 'Emory University', 'https://hejiecui.com/'),
    createData('Yanqiao Zhu', '/people/YanqiaoZhu.jpg', 'UCLA', 'https://sxkdz.github.io/'),
    createData('Xuan Kan', '/people/XuanKan.jpeg', 'Emory University', 'https://kanxuan.live/'),
]

const webRows = [
    createData('Wei Dai', '', 'Stanford University', 'https://dd.works/')
]


const TeamPage = () => {
  return (
    <Container style={
        {
            alignItems: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }
    }>
        <Helmet>
            <title>{titleName}</title>
        </Helmet>
        <h1 style={{padding: '40px'}}>Organizing Committee</h1>
        {/*  Horizontal align PersonCard */}

        <Divider style={
            {
                width: '100%',
                marginBottom: '50px',
            }
        }/>

        <Grow
            in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...{ timeout: 1000 }}
        >

            <Grid container spacing={1} justifyContent={'center'}>
                {rows.map(row => (
                    <PersonCard
                        key={row.name}
                        name={row.name}
                        photo={row.photo}
                        position={row.position}
                        link={row.link}
                        title={row.title}
                    />
                ))}
            </Grid>
        </Grow>

        <h1 style={{padding: '40px', paddingTop: '100px'}}>Volunteers / Student Organizers</h1>
        {/*  Horizontal align PersonCard */}

        <Divider style={
            {
                width: '100%',
                marginBottom: '50px',
            }
        }/>

        <Grow
            in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...{ timeout: 1000 }}
        >

            <Grid container spacing={1} justifyContent={'center'} paddingBottom={'20px'}>
                {studentRows.map(row => (
                    <PersonCard
                        key={row.name}
                        name={row.name}
                        photo={row.photo}
                        position={row.position}
                        link={row.link}
                    />
                ))}
            </Grid>
        </Grow>

        <h1 style={{padding: '40px', paddingTop: '100px'}}>Web Master</h1>
        {/*  Horizontal align PersonCard */}

        <Divider style={
            {
                width: '100%',
                marginBottom: '50px',
            }
        }/>

        <Grow
            in={true}
            style={{ transformOrigin: '0 0 0' }}
            {...{ timeout: 1000 }}
        >

            <Grid container spacing={1} justifyContent={'center'} paddingBottom={'20px'}>
                {webRows.map(row => (
                    <PersonCard
                        key={row.name}
                        name={row.name}
                        photo={row.photo}
                        position={row.position}
                        link={row.link}
                    />
                ))}
            </Grid>
        </Grow>

    </Container>
  )
}

export default TeamPage
